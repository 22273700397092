class UiInitializer {
    Initialize(id: string, options: object): void {
        if (options) {
            $('#' + id).tooltip(options);
        }
        else {
            $('#' + id).tooltip();
        }
    }
}

(<any>window).UiInitializer = new UiInitializer();