class BootstrapBreakpointWatcher {
    Initialize(objRef:any): void {
        $(document).ready(() => {
            function getBootstrapDeviceSize() {
                return $("#current-bootstrap-breakpoint").find("div:visible").first().attr("id");
            }

            function tellBlazorCurrentBootstrapBreakpoint() {
                var screen = getBootstrapDeviceSize();

                // tell it with jsInterop
                objRef.invokeMethodAsync("OnBootstrapBreakpointChanged", screen);
            }
            tellBlazorCurrentBootstrapBreakpoint();
            $(window).on("resize", tellBlazorCurrentBootstrapBreakpoint);
        });
    }
}

(<any>window).BootstrapBreakpointWatcher = new BootstrapBreakpointWatcher();