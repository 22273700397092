﻿window.alertUnsavedChanges = {

    alert: function (changed) {

        window.isChanged = changed;

        if (changed) {
            window.onbeforeunload = function (event) {
                event.preventDefault();
                event.returnValue = '';
            };
        } else {
            window.onbeforeunload = null;
        }
    },

    showMessage: function (message) {

        if (confirm(message)) {
            window.isChanged = false;
            return true;
        } else {
            return false;
        }

    },

    getChanged: function () {
        return window.isChanged;
    }
};