﻿window.ModalInterop = {
    CloseModalById: function (modalId) {
        $('#' + modalId).modal("hide");
    },

    ShowModalById: function (modalId) {
        $('#' + modalId).modal("show");
    },

    FocusInputByIdOnShowModal: function (modalId, inputId) {
        const _this = this;
        $(document).ready(function () {
            $('#' + modalId).on('shown.bs.modal', _this.FocusInputById(inputId));
        });
    },

    FocusInputById: function (inputId) {
        document.getElementById(inputId).focus();
    },

    FocusFirstTabbableInsideElementById: function (parentId) {
        const tabbablesQuerySelector = "input:not([tabindex='-1']), select:not([tabindex='-1']), textarea:not([tabindex='-1']), button:not([tabindex='-1']), a:not([tabindex='-1'])";
        const firstTabbable = document.getElementById(parentId).querySelector(tabbablesQuerySelector);
        if (firstTabbable !== "undefined") {
            firstTabbable.focus();
        }
    },
    
    FocusLastTabbableInsideElementById: function (parentId) {
        const tabbablesQuerySelector = "input:not([tabindex='-1']):not([disabled]), select:not([tabindex='-1']):not([disabled]), textarea:not([tabindex='-1']):not([disabled]), button:not([tabindex='-1']):not([disabled]), a:not([tabindex='-1'])";
        const allTabbables = document.getElementById(parentId).querySelectorAll(tabbablesQuerySelector);
        if (allTabbables.length > 0) {
            const lastTabbable = allTabbables[allTabbables.length - 1];
            lastTabbable.focus();
        }
    }
};